import React from 'react';
import ReactPlayer from 'react-player/lazy';

const VideoModal = ({ closeModal, open, play, video }) => {

  return (
    <div
      className={open ? 'video-modal is-open' : 'video-modal'}
      // style={{ transform: 'none' }}
    >
      <button className="close" onClick={closeModal}>
        close
        <span className="lines"></span>
      </button>
      <ReactPlayer
          className="video"
          playing={play}
          controls={true}
          url={video}
          width="80%"
          height="80%"
          config={{
            youtube: {
              playerVars: { origin: 'https://www.youtube.com' },
            },
          }}
      />
    </div>
  );
};

export default VideoModal;
