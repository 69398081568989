import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Play from '../../../svg/play.svg';

const VideoPlayer = ({ dataPartners, openModal }) => {
  const image = getImage(dataPartners?.image_cover?.localFile);

  return (
    <div className="video-player" role="presentation" onClick={openModal}>
      <div className="video-player__cover">
        <GatsbyImage 
          image={image} 
          alt="video"
        />
      </div>
      <span className="video-player__play">
        <span className="pulse"></span>
        <i className="play-icon">
          <Play />
        </i>
      </span>
    </div>
  );
};

export default VideoPlayer;
